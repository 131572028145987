import { Label } from "components/Form/Label"
import { HelpMessage } from "components/Form/HelpMessage"
import { ErrorsList } from "components/ErrorsList"
import { InputDate } from "components/InputDate"

export const DateSingleTextField = ({
  label,
  value = "",
  id,
  name,
  onChange,
  description,
  errors,
  schema,
  min,
  max,
}) => {
  const handleChange = (selectedDate) => {
    onChange?.(selectedDate ? selectedDate.toISOString() : null)
  }

  return (
    <div className="space-y-1">
      {label ? (
        <div className="pl-4">
          <Label htmlFor={id}>{label}</Label>
        </div>
      ) : null}
      {description ? <HelpMessage>{description}</HelpMessage> : null}
      <div>
        <DatePicker
          value={value}
          name={name}
          onChange={handleChange}
          id={id}
          schema={schema}
          min={min}
          max={max}
        />
      </div>
      {errors ? <ErrorsList errors={errors} /> : null}
    </div>
  )
}

const DatePicker = ({
  value,
  name,
  onChange,
  id,
  min,
  max,
  schema,
  calendarProps,
  ...props
}) => {
  const valueDate = value ? new Date(value) : null
  const minDate = min ? new Date(min) : undefined
  const maxDate = max ? new Date(max) : undefined

  return (
    <InputDate
      value={valueDate}
      name={name}
      onChange={onChange}
      id={id}
      minDate={minDate}
      maxDate={maxDate}
      variant={getVariant(schema.options.widget)}
      calendarProps={calendarProps}
      {...props}
    />
  )
}

const getVariant = (widget) => {
  switch (widget) {
    case "date_single_text":
      return "date"

    case "date_time_single_text":
      return "datetime"

    default:
      return "date"
  }
}
