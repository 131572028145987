import { Label } from "components/Form/Label"
import { HelpMessage } from "components/Form/HelpMessage"
import { ErrorsList } from "components/ErrorsList"
import { InputRange } from "components/InputRange"

export const RangeField = ({
  label,
  value: valueProps,
  id,
  name,
  onChange,
  description,
  errors,
  schema,
}) => {
  const min = Number(schema.options.attr.min)
  const max = Number(schema.options.attr.max)
  const step = schema.options.attr.step ? Number(schema.options.attr.step) : 1

  const value = valueProps ? Math.max(Number(valueProps), min) : min

  return (
    <div className="space-y-1">
      {label ? (
        <div className="pl-4">
          <Label htmlFor={id}>{label}</Label>
        </div>
      ) : null}
      {description ? <HelpMessage>{description}</HelpMessage> : null}
      <div>
        <InputRange
          initialValues={[value]}
          min={min}
          max={max}
          step={step}
          unit={schema.options.attr.unit}
          onChange={(values) => onChange(values[0])}
        />
        <input type="hidden" name={name} value={value} />
      </div>
      {errors ? <ErrorsList errors={errors} /> : null}
    </div>
  )
}
