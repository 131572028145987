import bundleConfig from "../../../../config/packages/fos_ckeditor.yaml"
import { CKEditor } from "ckeditor4-react"
import noop from "lodash/noop"
import { FormRow } from "./FormRow"

export const CKEditorField = ({
  label,
  value,
  id,
  name,
  onChange,
  description,
  errors,
  schema,
}) => {
  const config = schema.options?.config
    ? getConfig(schema.options.config)
    : defaultConfig

  const handleChange = (e) => {
    const value = e.editor.getData()
    onChange(value)
  }

  return (
    <FormRow label={label} id={id} description={description} errors={errors}>
      <CKEditor
        initData={value || ""}
        config={config}
        onChange={handleChange}
        name={name}
        editorUrl="/bundles/fosckeditor/ckeditor.js"
      />
      <textarea name={name} value={value} onChange={noop} className="hidden" />
    </FormRow>
  )
}

const getConfig = (configName) => {
  const configs = bundleConfig.fos_ck_editor.configs
  const config = configs[configName]

  return config
}

const defaultConfig = getConfig(bundleConfig.fos_ck_editor.default_config)
