import { useTranslator } from "components/Translator"
import { ObjectField } from "./ObjectField"

export const BirthdayField = (props) => {
  const translator = useTranslator()

  if (props.schema.type === "object") {
    props.schema.options.layout = "inline"

    props.schema.properties.year.options.propertyOrder = 2

    props.schema.properties.year.options.attr = {
      ...props.schema.properties.year.options.attr,
      maxlength: 4,
      placeholder: props.schema.options.date_time.placeholder.year,
    }

    props.schema.properties.month.options.propertyOrder =
      translator.locale === "fr" ? 1 : 0

    props.schema.properties.month.options.attr = {
      ...props.schema.properties.month.options.attr,
      maxlength: 2,
      placeholder: props.schema.options.date_time.placeholder.month,
    }

    props.schema.properties.day.options.propertyOrder =
      translator.locale === "fr" ? 0 : 1

    props.schema.properties.day.options.attr = {
      ...props.schema.properties.day.options.attr,
      maxlength: 2,
      placeholder: props.schema.options.date_time.placeholder.day,
    }
  }

  return <ObjectField {...props} />
}
