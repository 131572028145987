import { Label } from "components/Form/Label"
import { HelpMessage } from "components/Form/HelpMessage"
import { ErrorsList } from "components/ErrorsList"
import classNames from "classnames"
import { Button } from "components/Button"
import { TrashAlt, PlusCircle } from "components/Icon"
import { AutoField } from "@w3rone/json-schema-form"

export const CollectionField = ({
  label,
  value,
  id,
  name,
  onChange,
  description,
  errors,
  schema,
  readOnly = false,
  addButton,
}) => {
  const handleAdd = () => {
    onChange?.([...(value || []), undefined])
  }

  const handleRemove = (index) => {
    const defaultedValue = value || []

    onChange?.([
      ...defaultedValue.slice(0, index),
      ...defaultedValue.slice(index + 1),
    ])
  }

  return (
    <div className="space-y-1">
      {label ? (
        <div className="pl-4">
          <Label htmlFor={id}>{label}</Label>
        </div>
      ) : null}
      {description ? <HelpMessage>{description}</HelpMessage> : null}
      <div
        className={classNames({
          "space-y-4": schema.options.layout === "block",
          "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4":
            schema.options.layout === "inline",
          "grid grid-cols-1 md:grid-cols-2 gap-4":
            schema.options.layout === "two-col",
        })}
      >
        {value?.map((_value, index) => (
          <div key={index} className="space-y-1">
            {schema.options.collection.allowDelete && !readOnly ? (
              <div className="flex items-center space-x-1">
                <div className="border-t border-grey-light grow" />
                <Button
                  type="button"
                  onClick={() => handleRemove(index)}
                  variant="text"
                  className="grow-0 shrink-0"
                >
                  <TrashAlt className="w-4" />
                </Button>
              </div>
            ) : null}
            <div className="grow">
              <AutoField name={`${name}[${index}]`} />
            </div>
          </div>
        ))}
        {schema.options.collection.allowAdd && !readOnly ? (
          <div
            className={classNames({
              "self-center": schema.options.layout === "inline",
            })}
          >
            {addButton ? (
              addButton
            ) : (
              <Button
                type="button"
                variant="outline"
                color="secondary"
                onClick={handleAdd}
                iconRight={<PlusCircle className="w-4" />}
                className="w-full"
              >
                {label}
              </Button>
            )}
          </div>
        ) : null}
      </div>
      {errors ? <ErrorsList errors={errors} /> : null}
    </div>
  )
}
