import { CollectionField } from "components/Form/CollectionField"
import { ButtonBase } from "components/Button"
import { useTranslator } from "components/Translator"
import { PlusCircle } from "components/Icon"
import {
  getImageDate,
  getFilePropertyFromMediaType,
  getMediaType,
} from "domains/media/model"
import * as MediaType from "enums/MediaType"
import { getInitialValue } from "./getInitialValue"

export const CollectionMediaField = (props) => {
  const translator = useTranslator()

  const handleChange = async (e) => {
    const items = await Promise.all(
      [...e.target.files].map(async (file) => {
        const dataUrl = await readFileAsDataUrl(file)
        const type = getMediaType(file)
        const property = getFilePropertyFromMediaType(type)

        const item = {
          ...getInitialValue(props.schema.items),
          [property]: dataUrl,
          type,
          date: type === MediaType.PHOTO ? await getImageDate(file) : "",
        }

        return item
      })
    )

    props.onChange?.([...(props.value || []), ...items])
  }

  return (
    <CollectionField
      {...props}
      addButton={
        <ButtonBase
          tag="label"
          className="cursor-pointer w-full"
          iconLeft={<PlusCircle className="w-4" />}
          variant="outline"
          color="secondary"
        >
          {translator.trans(
            "Form.CollectionMediaWidget.add",
            null,
            "components"
          )}
          <input
            type="file"
            className="hidden"
            multiple
            onChange={handleChange}
          />
        </ButtonBase>
      }
    />
  )
}

const readFileAsDataUrl = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve(e.target.result)
    }

    reader.onerror = () => {
      reject(reader.error)
    }

    reader.readAsDataURL(file)
  })
}
