import { Carousel } from "components/Carousel"
import * as MediaType from "enums/MediaType"

export const MediasCarousel = ({
  medias,
  onChange,
  className,
  getMediaUrl,
}) => {
  const slides = medias.map((media) => mediaToSlide(media, getMediaUrl))
  return <Carousel slides={slides} onChange={onChange} className={className} />
}

export const mediaToSlide = (media, getMediaUrl) => {
  let slide

  switch (media.type.enum_value) {
    case MediaType.PHOTO:
      slide = (
        <ImageSlide
          image={media.imageFile}
          alt={media.alt}
          key={media.id}
          getMediaUrl={getMediaUrl}
        />
      )
      break

    case MediaType.VIDEO:
      slide = (
        <VideoSlide
          video={media.videoFile}
          key={media.id}
          getMediaUrl={getMediaUrl}
        />
      )
      break

    default:
      throw new Error(`Unkown media type: ${media.type.enum_value}`)
  }

  return <div className={"aspect-video"}>{slide}</div>
}

const ImageSlide = ({ image, alt, getMediaUrl }) => {
  return (
    <div className="relative bg-black h-full">
      <img
        className="w-full h-full object-contain object-center"
        src={getMediaUrl ? getMediaUrl(image) : image.url}
        alt={alt ? alt : ""}
        loading={"lazy"}
      />
    </div>
  )
}

const VideoSlide = ({ video, getMediaUrl }) => {
  return (
    <video
      controls
      key={video.id}
      className="w-full h-full object-center object-cover"
    >
      <source
        src={getMediaUrl ? getMediaUrl(video) : video.url}
        type="video/mp4"
      />
    </video>
  )
}
