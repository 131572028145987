import { Label } from "components/Form/Label"
import { HelpMessage } from "components/Form/HelpMessage"
import { ErrorsList } from "components/ErrorsList"
import { InputRange } from "components/InputRange"

export const IntegerIntervalField = ({
  label,
  value: valueProps,
  id,
  name,
  onChange,
  description,
  errors,
  schema,
}) => {
  const min = Number(schema.properties.min.options.attr.min)
  const max = Number(schema.properties.max.options.attr.max)
  const step = schema.properties.min.options.attr.step
    ? Number(schema.properties.min.options.attr.step)
    : 1

  const values = [
    Math.max(valueProps?.min ? Number(valueProps.min) : min, min),
    Math.min(valueProps?.max ? Number(valueProps.max) : max, max),
  ]

  return (
    <div className="space-y-1">
      {label ? (
        <div className="pl-4">
          <Label htmlFor={id}>{label}</Label>
        </div>
      ) : null}
      {description ? <HelpMessage>{description}</HelpMessage> : null}
      <div>
        <InputRange
          initialValues={values}
          min={min}
          max={max}
          step={step}
          unit={schema.properties.min.options.attr.unit}
          onChange={(values) => onChange({ min: values[0], max: values[1] })}
        />
        <input type="hidden" name={`${name}[min]`} value={values[0]} />
        <input type="hidden" name={`${name}[max]`} value={values[1]} />
      </div>
      {errors ? <ErrorsList errors={errors} /> : null}
    </div>
  )
}
