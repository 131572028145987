import { Text } from "components/Text"
import PropTypes from "prop-types"
import { Markdown } from "components/Markdown"

export const Label = ({ children, htmlFor, id }) => {
  return (
    <Markdown
      components={{
        p({ children }) {
          return (
            <Text
              tag="label"
              variant="body2"
              className="text-default"
              htmlFor={htmlFor}
              id={id}
            >
              {children}
            </Text>
          )
        },
      }}
    >
      {children}
    </Markdown>
  )
}

Label.propTypes = {
  children: PropTypes.node,
  htmlFor: PropTypes.string,
  id: PropTypes.string,
}
