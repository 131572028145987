import { AddressInput } from "components/AddressInput"
import { getInitialValue } from "components/Form/getInitialValue"
import { Fragment } from "react"
import { FormRow } from "./FormRow"
import { useField } from "@w3rone/json-schema-form"

export const AddressField = ({
  label,
  value,
  id,
  name,
  onChange,
  description,
  errors: errorsProps,
  schema,
}) => {
  const handleSelectPlace = (place) => {
    onChange(place)
  }

  const rawField = useField(`${name}[raw]`)

  const errors = [...errorsProps, ...rawField.errors]

  return (
    <FormRow label={label} id={id} description={description} errors={errors}>
      {Object.entries(schema.properties).map(([key, propertySchema]) => {
        switch (key) {
          case "raw":
            return (
              <AddressInput
                name={`${name}[${key}]`}
                id={id}
                key={key}
                value={value?.raw || ""}
                onChange={(newValue) => {
                  if (newValue !== value?.[key]) {
                    onChange({ ...getInitialValue(schema), raw: newValue })
                  }
                }}
                onSelectPlace={handleSelectPlace}
                types={schema.options.autocompleteTypes}
                {...propertySchema.options.attr}
              />
            )

          // TODO maybe we can just call `AutoField` for all these fields
          case "coords":
            return (
              <Fragment key={key}>
                <input
                  type="hidden"
                  name={`${name}[${key}][lng]`}
                  value={value?.coords?.lng || ""}
                />
                <input
                  type="hidden"
                  name={`${name}[${key}][lat]`}
                  value={value?.coords?.lat || ""}
                />
              </Fragment>
            )

          default:
            return (
              <input
                type="hidden"
                name={`${name}[${key}]`}
                key={key}
                value={value?.[key] || ""}
              />
            )
        }
      })}
    </FormRow>
  )
}
