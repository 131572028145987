import cx from "classnames"
import PropTypes from "prop-types"
import { Check } from "components/Icon/Check"
import { Minus } from "components/Icon/Minus"
import "components/Checkbox/Checkbox.css"

export function Checkbox(props) {
  const { className, indeterminate, ...rest } = props

  const classNames = cx(
    { "disabled:bg-primary-light": indeterminate },
    className,
    "Checkbox",
    "bg-white",
    "border-2",
    "border-solid",
    "rounded-sm",
    "appearance-none",
    "w-4",
    "h-4",
    "outline-none",
    "box-border",
    "focus-visible:ring-4",
    "focus:ring-primary-lighter"
  )

  return (
    <div className="relative inline-flex Checkbox">
      <input className={classNames} type="checkbox" {...rest} />
      {indeterminate ? (
        <Minus className={cx("Checkbox__check", "pointer-events-none")} />
      ) : (
        <Check className={cx("Checkbox__check", "pointer-events-none")} />
      )}
    </div>
  )
}

Checkbox.propTypes = {
  className: PropTypes.string,
  indeterminate: PropTypes.bool,
}
