import PropTypes from "prop-types"
import { useTranslator } from "components/Translator"

export const Price = ({ amount, currency, additionalUnit }) => {
  const translator = useTranslator()

  const formattedPrice = formatPrice({
    amount,
    currency,
    locale: translator.locale,
  })

  return `${formattedPrice}${additionalUnit}`
}

Price.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  additionalUnit: PropTypes.string,
}

Price.defaultProps = {
  additionalUnit: "",
}

export const formatPrice = ({ amount, currency, locale }) => {
  const isRound = Math.trunc(amount) === amount

  return amount.toLocaleString(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: isRound ? 0 : 2,
  })
}
