import "components/InputGroup/style.css"
import PropTypes from "prop-types"
import cx from "classnames"
import { useBreakpoints } from "hooks/useBreakpoints"

export const InputGroup = ({ children, direction: directionProps }) => {
  const { lg } = useBreakpoints()
  const direction = directionProps ?? lg ? "horizontal" : "vertical"

  return (
    <div
      className={cx(
        "InputGroup flex flex-col space-y-2 lg:space-y-0 lg:flex-row",
        {
          "InputGroup--horizontal": direction === "horizontal",
          "InputGroup--vertical": direction === "vertical",
        }
      )}
    >
      {children}
    </div>
  )
}

InputGroup.propType = {
  direction: PropTypes.oneOf(["horizontal", "vertical"]).isRequired,
}
