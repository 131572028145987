import { Alerter } from "components/Alert"
import { Form, useFormRequestContext } from "@w3rone/json-schema-form"
import { Button } from "components/Button"
import { StringField } from "components/Form/StringField"
import { ObjectField } from "components/Form/ObjectField"
import { CheckboxField } from "components/Form/CheckboxField"
import { ChoiceField } from "components/Form/ChoiceField"
import { DateSingleTextField } from "components/Form/DateSingleTextField"
import { AddressField } from "components/Form/AddressField"
import { FileField } from "components/Form/FileField"
import { PriceField } from "components/Form/PriceField"
import { CurrencyField } from "components/Form/CurrencyField"
import { CollectionField } from "components/Form/CollectionField"
import { CollectionMediaField } from "components/Form/CollectionMediaField"
import { DateIntervalField } from "components/Form/DateIntervalField"
import { RangeField } from "components/Form/RangeField"
import { IntegerIntervalField } from "components/Form/IntegerIntervalField"
import { SharingPotField } from "components/Form/SharingPotField"
import { CKEditorField } from "components/Form/CKEditorField"
import { AddressExpandedField } from "components/Form/AddressExpandedField"
import { CityField } from "components/Form/CityField"
import { MediaGalleryField } from "components/Form/MediaGalleryField"
import { MediaField } from "components/Form/MediaField"
import { BirthdayField } from "components/Form/BirthdayField"
import { WysiwygField } from "components/Form/WysiwygField"
import { useTranslator } from "components/Translator"

export function AutoForm({
  onSubmit,
  onSuccess,
  onError,
  components,
  submitLabel,
  ...props
}) {
  const handleSuccess = (data) => {
    onSuccess?.(data)

    if (data.message) {
      Alerter.success(data.message)
    }

    if (data.redirect_url) {
      if (data.message) {
        setTimeout(() => {
          window.location.assign(data.redirect_url)
        }, 3000)
      } else {
        window.location.assign(data.redirect_url)
      }
    }
  }

  const handleError = (error) => {
    onError?.(error)

    const message = error.message || "Une erreur est survenue."
    Alerter.error(message)
  }

  const translator = useTranslator()

  return (
    <Form
      components={{ ...DEFAULT_COMPONENTS, ...components }}
      onSuccess={handleSuccess}
      onError={handleError}
      onSubmit={onSubmit}
      submitLabel={
        submitLabel ||
        translator.trans("AutoForm.submitLabel", null, "components")
      }
      {...props}
      fieldMapper={fieldMapper}
    />
  )
}

export const Root = ({ children }) => {
  return <div className={"space-y-6"}>{children}</div>
}

export const Actions = ({ submitLabel }) => {
  const request = useFormRequestContext()
  const isLoading = request.status === "loading"

  return (
    <Button type="submit" color="primary" disabled={isLoading} busy={isLoading}>
      {submitLabel}
    </Button>
  )
}

export const ActionsWrapper = ({ children }) => {
  return <div className="flex justify-center md:justify-end">{children}</div>
}

const DEFAULT_COMPONENTS = {
  Actions: Actions,
  ActionsWrapper: ActionsWrapper,
  Root: Root,
}

const fieldMapper = (fieldSchema) => {
  const widgets = new Map([
    ["text", StringField],
    ["password", StringField],
    ["email", StringField],
    ["textarea", StringField],
    ["url", StringField],
    ["tel", StringField],
    ["hidden", StringField],
    ["integer", StringField],
    ["number", StringField],
    ["range", StringField],
    ["color", StringField],
    ["money", StringField],

    ["choice", ChoiceField],
    ["entity", ChoiceField],
    ["entity_img", ChoiceField],
    ["country", ChoiceField],

    ["checkbox", CheckboxField],

    ["date_single_text", DateSingleTextField],
    ["date_time_single_text", DateSingleTextField],

    ["date_interval", DateIntervalField],

    ["birthday_text", BirthdayField],

    ["address", AddressField],
    ["address_expanded", AddressExpandedField],
    ["city", CityField],

    ["vich_file", FileField],
    ["vich_image", FileField],

    ["media", MediaField],
    ["media_gallery", MediaGalleryField],

    ["price", PriceField],
    ["currency", CurrencyField],

    ["collection", CollectionField],
    ["collection_media", CollectionMediaField],

    ["range", RangeField],
    ["integer_interval", IntegerIntervalField],

    ["sharing_pot", SharingPotField],

    ["c_k_editor", CKEditorField],
    ["wysiwyg", WysiwygField],
  ])

  const component = widgets.get(fieldSchema.options.widget)

  if (component) {
    return component
  }

  if (fieldSchema.type === "object") {
    return ObjectField
  }

  return TodoField
}

const TodoField = ({ schema, name }) => {
  return (
    <div>
      TODO [type:{schema.type},widget:{schema.options.widget}]({name})
    </div>
  )
}
