// TODO this should be either extracted or made generic

import {
  isAutocompleteFieldSchema,
  isCollectionFieldSchema,
  isMultipleChoiceFieldSchema,
} from "@w3rone/json-schema-form"

export const getInitialValue = (schema, entity) => {
  if (schema.type === "boolean") {
    return entity ?? schema.default ?? false
  }

  if (schema.type === "string") {
    if (schema.options.widget === "choice") {
      return entity?.enum_value ?? entity ?? schema.default ?? ""
    }

    if (schema.options.widget === "entity") {
      if (entity?.id) {
        return String(entity.id)
      } else if (schema.default) {
        return schema.default
      } else {
        return ""
      }
    }

    if (entity) {
      if (entity.enum_value) {
        return entity.enum_value
      }

      if (entity.id) {
        return String(entity.id)
      }

      return entity
    }

    if (schema.default) {
      return schema.default
    }

    return ""
  }

  if (schema.type === "integer" || schema.type === "number") {
    if (entity !== undefined && entity !== null) {
      return String(entity)
    } else if (schema.default) {
      return schema.default
    } else {
      return ""
    }
  }

  if (schema.type === "object") {
    if (
      [
        FieldWidget.BIRTHDAY,
        FieldWidget.DATE,
        FieldWidget.DATE_CHOICE,
        FieldWidget.DATETIME,
        FieldWidget.TIME,
        FieldWidget.TIME_CHOICE,
      ].includes(schema.options.widget)
    ) {
      const date = entity ? new Date(entity) : null

      return {
        year: date ? date.getUTCFullYear().toString() : "",
        month: date ? (date.getUTCMonth() + 1).toString() : "",
        day: date ? date.getUTCDate().toString() : "",
        hour: date ? date.getUTCHours().toString() : "",
        minute: date ? date.getUTCMinutes().toString() : "",
        second: date ? date.getUTCSeconds().toString() : "",
      }
    }

    if ([FieldWidget.IMAGE, FieldWidget.FILE].includes(schema.options.widget)) {
      return entity?.thumbs?.cache ?? entity?.url ?? ""
    }

    if (schema.options.widget === FieldWidget.ADDRESS) {
      const baseValue = getObjectInitialValue(schema, entity)

      if (entity) {
        return {
          ...baseValue,
          raw: baseValue.formatted,
        }
      }

      return baseValue
    }

    if (schema.options.widget === FieldWidget.PHONE) {
      return {
        country: entity?.countryAlpha2 || "",
        number: entity?.nationalNumber || "",
      }
    }

    if (isAutocompleteFieldSchema(schema)) {
      const entityFieldName = Object.keys(schema.properties).find(
        (key) => key !== "ac_keyword"
      )

      const value = {
        ac_keyword: entity ? entity[schema.options.autocomplete.label] : "",
        [entityFieldName || "id"]: entity
          ? entity[schema.options.autocomplete.id]
          : "",
      }

      return value
    }

    return getObjectInitialValue(schema, entity)
  }

  if (schema.type === "array") {
    if (isCollectionFieldSchema(schema)) {
      if (entity) {
        return entity.map((entity, index) => {
          const itemSchema = Array.isArray(schema.items)
            ? structuredClone(schema.items[index])
            : structuredClone(schema.items)

          if (!itemSchema) {
            console.error({ schema, index })
            throw new Error(
              `Couldn't find collection item schema: index ${index}`
            )
          }

          /*
           * When a collection contains autocomplete items, the fields have item
           * index duplicated as names. For example user[partners][0][0] or
           * user[partners][1][1]. We need to handle this specific case in hydration.
           *
           * That's why we replace the entity field name (the one that is not ac_keyword)
           * with the index of the item in the collection in the schema.
           */
          if (isAutocompleteFieldSchema(itemSchema)) {
            const entityFieldName = Object.keys(itemSchema.properties).find(
              (key) => key !== "ac_keyword"
            )

            if (entityFieldName) {
              const property = itemSchema.properties[entityFieldName]

              if (property) {
                itemSchema.properties[index] = property
              }

              if (String(index) !== entityFieldName) {
                delete itemSchema.properties[entityFieldName]
              }
            }
          }

          return getInitialValue(itemSchema, entity)
        })
      }

      return []
    } else if (isMultipleChoiceFieldSchema(schema)) {
      if (entity) {
        return entity.map((entity) => {
          return entity?.enum_value || entity?.id?.toString() || entity || ""
        })
      }

      return []
    }
  }
}

const FieldWidget = {
  CHOICE: "choice",
  ENTITY: "entity",
  IMAGE: "vich_image",
  FILE: "vich_file",
  BIRTHDAY: "birthday_text",
  DATE: "date",
  DATE_CHOICE: "date_choice",
  DATETIME: "datetime",
  ADDRESS: "address",
  PHONE: "phone_number",
  AUTOCOMPLETE: "autocomplete",
  TIME: "time",
  TIME_CHOICE: "time_choice",
}

const getObjectInitialValue = (schema, entity) => {
  return Object.entries(schema.properties).reduce((value, entry) => {
    const [name, propertySchema] = entry
    const entityChunk = entity?.[name]

    value[name] = getInitialValue(propertySchema, entityChunk)

    return value
  }, {})
}
