import { FormRow } from "components/Form/FormRow"
import { WysiwygEditor, valueToMarkdown } from "components/WysiwygEditor"

export const WysiwygField = ({
  label,
  value: valueProps = "",
  id,
  name,
  onChange,
  description,
  errors,
  schema,
}) => {
  const value = valueProps ? String(valueProps) : ""

  return (
    <FormRow label={label} id={id} description={description} errors={errors}>
      <WysiwygEditor
        placeholder={schema.options.attr?.placeholder}
        onChange={(value) => onChange(valueToMarkdown(value))}
        initialValue={value}
        enabledWidgets={schema.options.wysiwyg.options}
      />
      <textarea
        className={"hidden"}
        value={value}
        name={name}
        readOnly={true}
      />
    </FormRow>
  )
}
