import { useBreakpoints } from "hooks/useBreakpoints"
import ReactSelect, { components } from "react-select"
import { useTranslator } from "components/Translator"
import { Text } from "components/Text"
import cx from "classnames"
import { CaretDown } from "components/Icon/CaretDown"
import { Check } from "components/Icon/Check"
import { ArrowLeft } from "components/Icon/ArrowLeft"
import { MenuItem } from "components/MenuItem"
import { MenuItemGroup } from "components/MenuItemGroup"
import { Tag } from "components/Tag"
import * as React from "react"
import useSize from "@react-hook/size"
import PropTypes from "prop-types"
import { Times } from "components/Icon/Times"
import { InputBase } from "components/Input"
import { UserCircle } from "components/Icon/UserCircle"
import { Avatar } from "components/Avatar"
import { isTouchDevice } from "utils/isTouchDevice"

export const Select = ({
  value: valueProps,
  defaultValue,
  onChange,
  placeholder,
  ...props
}) => {
  const { md } = useBreakpoints()
  const translator = useTranslator()
  const [value, setValue] = React.useState(() => {
    if (valueProps) {
      return valueProps
    }

    if (defaultValue) {
      return defaultValue
    }

    return undefined
  })

  const handleChange = (selectedValues) => {
    setValue(selectedValues)

    if (onChange) {
      onChange(selectedValues)
    }
  }

  React.useEffect(() => {
    if (valueProps !== undefined) {
      setValue(valueProps)
    }
  }, [valueProps])

  const commonProps = {
    noOptionsMessage: () => translator.trans("no_options", null, "select"),
    placeholder: placeholder ?? translator.trans("placeholder", null, "select"),
    hideSelectedOptions: false,
    value,
    closeMenuOnSelect: !props.isMulti,
    styles: {
      input: (provided) => ({
        ...provided,
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
      }),
    },
  }

  const finalProps = {
    ...commonProps,
    ...props,
    components: {
      Control,
      ClearIndicator,
      DropdownIndicator,
      Group,
      IndicatorSeparator: () => null,
      MultiValue,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer,
      Input,
      ...props.components,
    },
    onChange: handleChange,
    isSearchable: !md || !isTouchDevice(),
  }

  if (md) {
    return <SelectDesktop {...finalProps} />
  }

  return <SelectMobile {...finalProps} />
}

const SelectDesktop = (props) => {
  return <ReactSelect {...props} menuPosition="fixed" />
}

const SelectMobileContext = React.createContext()

const SelectMobile = (props) => {
  const { value, placeholder, isDisabled, components } = props
  const [isOpen, setIsOpen] = React.useState(false)

  const topBarRef = React.useRef(null)
  const [, topBarHeight] = useSize(topBarRef)

  const handleChange = (...args) => {
    if (props.onChange) {
      props.onChange(...args)
    }

    if (!props.isMulti) {
      setIsOpen(false)
    }
  }

  return (
    <SelectMobileContext.Provider value={{ topBarHeight }}>
      <div>
        <SelectButton
          value={value}
          placeholder={placeholder}
          disabled={isDisabled}
          onClick={isDisabled ? undefined : () => setIsOpen(true)}
          components={components}
        />
        <div
          className={cx(
            "fixed top-0 left-0 w-full h-full bg-grey-lighter z-50",
            {
              hidden: !isOpen,
            }
          )}
        >
          <div
            className="flex absolute top-0 left-0 items-center py-3 px-5 w-full bg-white shadow-2"
            ref={topBarRef}
          >
            <button
              type="button"
              className="w-10 h-10 text-primary-dark"
              onClick={() => setIsOpen(false)}
            >
              <ArrowLeft className="w-4" />
            </button>
            <ReactSelect
              className="w-full"
              {...props}
              menuIsOpen={true}
              styles={{
                container: (base) => ({
                  ...base,
                  flexGrow: 1,
                }),
              }}
              components={{
                ...components,
                Menu: MenuMobile,
                MenuList: MenuListMobile,
                MultiValue: MultiValueMobile,
                DropdownIndicator: DropdownIndicatorMobile,
                SingleValue: SingleValueMobile,
              }}
              onChange={handleChange}
              isClearable={false}
            />
          </div>
        </div>
      </div>
    </SelectMobileContext.Provider>
  )
}

export const SingleValue = (props) => {
  const { children, innerProps, isDisabled } = props

  return (
    <Text
      variant="tag"
      {...innerProps}
      className={cx(
        { "text-default": !isDisabled, "text-light": isDisabled },
        "truncate"
      )}
      style={{ lineHeight: 1 }}
    >
      {children}
    </Text>
  )
}

const ClearIndicator = (props) => {
  const { className, innerProps } = props

  return (
    <div {...innerProps} className={cx(className, "px-2")}>
      <Times className="w-3" />
    </div>
  )
}

const DropdownIndicator = (props) => {
  const { isDisabled, innerProps } = props

  return (
    <div {...innerProps} className="px-2">
      <CaretDown
        className={cx(
          {
            "text-light": isDisabled,
            "text-primary-dark": !isDisabled,
          },
          "w-3"
        )}
      />
    </div>
  )
}

const Control = (props) => {
  const { innerRef, innerProps, children, isDisabled } = props

  return (
    <InputBase ref={innerRef}>
      <div className="w-full">
        <div
          {...innerProps}
          className={cx("flex items-center space-x-2", {
            "bg-grey-light": isDisabled,
            "bg-white": !isDisabled,
          })}
        >
          {children}
        </div>
      </div>
    </InputBase>
  )
}

const ValueContainer = (props) => {
  const { children } = props

  return (
    <div className="flex overflow-hidden flex-wrap items-center leading-none box-border grow">
      {children}
    </div>
  )
}

const Placeholder = (props) => {
  const { children, innerProps } = props

  return (
    <div {...innerProps} className="leading-none text-light truncate">
      {children}
    </div>
  )
}

const Option = (props) => {
  const {
    isDisabled,
    isSelected,
    isFocused,
    innerRef,
    innerProps,
    isMulti,
    data,
  } = props

  return (
    <MenuItem
      label={data.label}
      caption={data.caption}
      disabled={isDisabled}
      selected={isSelected}
      focused={isFocused}
      onSelect={innerProps.onClick}
      ref={innerRef}
      endAdornment={
        <React.Fragment>
          {isMulti && isSelected ? (
            <Check className="w-4 text-primary-default" />
          ) : null}
          {data.img ? (
            <Avatar
              size="small"
              alt=""
              src={data.img.thumbs ? data.img.thumbs.w75 : data.img.url}
              fallback={<UserCircle className="w-4" />}
            />
          ) : null}
        </React.Fragment>
      }
      role="option"
    />
  )
}

const Group = (props) => {
  const { children, label } = props

  return <MenuItemGroup title={label}>{children}</MenuItemGroup>
}

const MultiValue = (props) => {
  const { children, innerProps, removeProps } = props

  return (
    <span className="inline-block my-0.5 mr-1 last:mr-0">
      <Tag
        color="default"
        tag="button"
        onClick={removeProps.onClick}
        label={children}
        iconEnd={<Times className="w-3" />}
        {...innerProps}
      />
    </span>
  )
}

const MultiValueMobile = () => null
const DropdownIndicatorMobile = () => null
const SingleValueMobile = () => null

const MenuMobile = (props) => {
  const { children, innerRef, innerProps } = props
  const { topBarHeight } = React.useContext(SelectMobileContext)

  return (
    <div
      {...innerProps}
      ref={innerRef}
      className="fixed bottom-0 left-0 w-full"
      style={{ top: topBarHeight }}
    >
      {children}
    </div>
  )
}

const MenuListMobile = (props) => {
  const { children, innerRef, innerProps } = props

  return (
    <div
      className="overflow-y-auto relative py-1 h-full box-border"
      ref={innerRef}
      {...innerProps}
    >
      {children}
    </div>
  )
}

const SelectButton = (props) => {
  const { state, placeholder, value, disabled, onClick, components } = props

  return (
    <button
      type="button"
      className="w-full text-left"
      disabled={disabled}
      onClick={onClick}
    >
      <InputBase state={state} disabled={disabled}>
        <div className="grow">
          {!value || value.length === 0 ? (
            <Text variant="body1" className="text-light">
              {placeholder}
            </Text>
          ) : value && Array.isArray(value) ? (
            value.map((object) => {
              return (
                <span key={object.label} className="m-1">
                  <Tag
                    color="default"
                    label={object.label}
                    iconEnd={<Times className="w-3" />}
                  />
                </span>
              )
            })
          ) : (
            <components.SingleValue variant="body1">
              {value.label}
            </components.SingleValue>
          )}
        </div>
        <CaretDown className="ml-auto w-5 h-6 text-primary-dark" />
      </InputBase>
    </button>
  )
}

const valueShape = PropTypes.shape({ label: PropTypes.string })

SelectButton.propTypes = {
  className: PropTypes.string,
  state: PropTypes.oneOf(["default", "success", "error"]),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([valueShape, PropTypes.arrayOf(valueShape)]),
  placeholder: PropTypes.string,
}

SelectButton.defaultProps = {
  state: "default",
}

const Input = (props) => {
  return <components.Input {...props} type={"search"} autoComplete={"nope"} />
}
