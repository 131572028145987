import * as React from "react"
import mbxClient from "@mapbox/mapbox-sdk"
import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding"

const MapboxContext = React.createContext()

export const MapboxProvider = ({
  accessToken = process.env.MAPBOX_ACCESS_TOKEN,
  children,
}) => {
  const client = React.useMemo(() => mbxClient({ accessToken }), [accessToken])

  return (
    <MapboxContext.Provider value={{ client }}>
      {children}
    </MapboxContext.Provider>
  )
}

export const useMapboxClient = () => {
  const context = React.useContext(MapboxContext)

  if (!context) {
    throw new Error("`useMapboxClient` must be wrapped in a `MapboxProvider`")
  }

  return context.client
}

export const useGeocoding = () => {
  const client = useMapboxClient()
  const geocoder = mbxGeocoding(client)

  return geocoder
}
