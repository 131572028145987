import ReactMarkdown from "react-markdown"
import { Text } from "components/Text"
import { Link } from "components/Link"
import rehypeRaw from "rehype-raw"
import cx from "classnames"
import "./style.css"

export const Markdown = ({ children, components = null, linkTarget }) => {
  return (
    <div className={"Markdown"}>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          h1({ children }) {
            return (
              <Text variant="headline1" tag="h1">
                {children}
              </Text>
            )
          },
          h2({ children }) {
            return (
              <Text variant="headline2" tag="h2">
                {children}
              </Text>
            )
          },
          h3({ children }) {
            return (
              <Text variant="headline3" tag="h3">
                {children}
              </Text>
            )
          },
          h4({ children }) {
            return (
              <Text variant="headline4" tag="h4">
                {children}
              </Text>
            )
          },
          h5({ children }) {
            return (
              <Text variant="headline5" tag="h5">
                {children}
              </Text>
            )
          },
          h6({ children }) {
            return (
              <Text variant="headline6" tag="h6">
                {children}
              </Text>
            )
          },
          p({ children }) {
            return (
              <Text variant="body1" tag="p">
                {children}
              </Text>
            )
          },
          a({ children, node: _node, ...props }) {
            return <Link {...props}>{children}</Link>
          },
          ul({ children }) {
            return <List>{children}</List>
          },
          ol({ children }) {
            return <List ordered={true}>{children}</List>
          },
          ...components,
        }}
        linkTarget={linkTarget}
      >
        {children}
      </ReactMarkdown>
    </div>
  )
}

const List = ({ children: childrenProps, ordered }) => {
  const children = childrenProps.filter(
    (children) => typeof children !== "string"
  )

  return (
    <Text
      variant="body1"
      tag={ordered ? "ol" : "ul"}
      className={cx("list-inside", {
        "list-disc": !ordered,
        "list-decimal": ordered,
      })}
    >
      {children}
    </Text>
  )
}
