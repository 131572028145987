import { Checkbox } from "components/Checkbox"
import {
  FormControlLabel,
  FormControlLabelCard,
} from "components/Form/FormControlLabel"
import { HelpMessage } from "components/Form/HelpMessage"
import { ErrorsList } from "components/ErrorsList"

export const CheckboxField = ({
  value: valueProps,
  onChange,
  name,
  label,
  errors,
  description,
  schema,
  id,
}) => {
  const value = Boolean(valueProps)

  const Component =
    schema.options.layout === "card" ? FormControlLabelCard : FormControlLabel

  return (
    <div className="space-y-1">
      <Component
        control={
          <Checkbox
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
            name={name}
            id={id}
          />
        }
        label={label}
        caption={schema.options?.caption}
      />
      {schema.description ? <HelpMessage>{description}</HelpMessage> : null}
      {errors ? <ErrorsList errors={errors} /> : null}
    </div>
  )
}
