import { Label } from "components/Form/Label"
import { HelpMessage } from "components/Form/HelpMessage"
import { ErrorsList } from "components/ErrorsList"
import { AddressInput } from "components/AddressInput"

export const CityField = ({
  label,
  value,
  id,
  name,
  onChange,
  description,
  errors,
}) => {
  const handleSelectPlace = (feature) => {
    onChange(feature.formatted)
  }

  return (
    <div className="space-y-1">
      {label ? (
        <div className="pl-4">
          <Label htmlFor={id}>{label}</Label>
        </div>
      ) : null}
      {description ? <HelpMessage>{description}</HelpMessage> : null}
      <div>
        <AddressInput
          name={name}
          id={id}
          value={value || ""}
          onChange={onChange}
          onSelectPlace={handleSelectPlace}
          types={["place"]}
        />
      </div>
      {errors ? <ErrorsList errors={errors} /> : null}
    </div>
  )
}
