import EXIF from "exif-js"
import * as MediaType from "enums/MediaType"

/**
 * @param {File} image
 * @return {Promise<any>}
 */
export const getImageMetadata = async (image) => {
  return new Promise((resolve) => {
    EXIF.getData(image, function () {
      const metadata = EXIF.getAllTags(this)

      resolve(metadata)
    })
  })
}

export const exifDateTimeToDate = (exifDateTime) => {
  const [date, time] = exifDateTime.split(" ")
  const [year, month, day] = date.split(":")
  const [hours, minutes, seconds] = time.split(":")

  return new Date(year, month - 1, day, hours, minutes, seconds)
}

/**
 * @param {File} image
 * @return {Promise<Date|null>}
 */
export const getImageDate = async (image) => {
  const metadata = await getImageMetadata(image)

  if (metadata.DateTimeOriginal) {
    return exifDateTimeToDate(metadata.DateTimeOriginal)
  }

  return null
}

/**
 * @param {File}
 */
export const getMediaType = (media) => {
  const [fileType] = media.type.split("/")

  switch (fileType) {
    case "image":
      return MediaType.PHOTO
    case "video":
      return MediaType.VIDEO
    default:
      throw new Error("Invalid file type")
  }
}

export const getFilePropertyFromMediaType = (mediaType) => {
  switch (mediaType) {
    case MediaType.PHOTO:
      return "imageFile"

    case MediaType.VIDEO:
      return "videoFile"

    default:
      throw new Error("Invalid media type")
  }
}
