import DatePicker from "react-date-picker/dist/entry.nostyle"
import DateTimePicker from "react-datetime-picker/dist/entry.nostyle"
import { InputBase } from "components/Input"
import * as React from "react"
import "components/InputDate/style.css"
import { Times } from "components/Icon/Times"
import { CalendarDay } from "components/Icon/CalendarDay"
import PropTypes from "prop-types"
import { useTranslator } from "components/Translator"
import { usePopper } from "react-popper"

export const InputDate = ({
  initialValue,
  value,
  onChange,
  minDate,
  maxDate,
  name,
  id,
  variant = "date",
  calendarProps,
  ...props
}) => {
  const [date, setDate] = React.useState(initialValue || value)
  const [open, setOpen] = React.useState(false)

  const translator = useTranslator()

  const handleChange = (selectedDate) => {
    setDate(selectedDate)

    if (onChange) {
      onChange(selectedDate)
    }
  }

  React.useEffect(() => {
    if (value !== undefined) {
      setDate(value)
    }
  }, [value])

  const Component = variant === "date" ? DatePicker : DateTimePicker

  const [referenceElement, setReferenceElement] = React.useState(null)
  const [popperElement, setPopperElement] = React.useState(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
  })

  React.useEffect(() => {
    if (!referenceElement) {
      return
    }

    const inputs = Array.from(referenceElement.querySelectorAll("input"))

    // When we show a datetime picker, we want the hours and minutes to remain editable manually
    inputs.slice(0, 4).forEach((input) => {
      input.readOnly = true
    })
  }, [referenceElement])

  return (
    <InputBase
      id={id}
      startAdornment={<CalendarDay className="w-4 text-primary-default" />}
      ref={setReferenceElement}
      {...props}
    >
      <Component
        calendarAriaLabel={translator.trans(
          open ? "InputDate.close" : "InputDate.open",
          null,
          "components"
        )}
        clearAriaLabel={translator.trans("InputDate.clear", null, "components")}
        onCalendarOpen={() => setOpen(true)}
        onCalendarClose={() => setOpen(false)}
        isOpen={open}
        onChange={handleChange}
        value={date}
        showLeadingZeros={true}
        calendarIcon={null}
        clearIcon={<Times className="w-3 hover:text-primary-default" />}
        minDate={minDate}
        maxDate={maxDate}
        name={name}
        disableClock={true}
        showFixedNumberOfWeeks={true}
        renderCalendar={(calendar) => {
          return (
            <div
              style={{
                ...styles.popper,
                visibility: open ? "visible" : "hidden",
              }}
              {...attributes.popper}
              className="z-10"
              ref={setPopperElement}
            >
              {calendar}
            </div>
          )
        }}
        {...calendarProps}
      />
    </InputBase>
  )
}

InputDate.propTypes = {
  initialValue: PropTypes.instanceOf(Date),
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
}
