import cx from "classnames"
import PropTypes from "prop-types"
import { useCardContext } from "components/Card/Card"

export function CardContent(props) {
  const { className, children } = props

  const { spacing } = useCardContext()

  const classNames = cx(
    { "py-4 px-5": spacing === "medium", "p-2": spacing === "small" },
    className
  )

  return <div className={classNames}>{children}</div>
}

CardContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
