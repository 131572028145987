import { PriceField } from "./PriceField"
import { useTranslator } from "components/Translator"
import { ExclamationTriangle } from "components/Icon"
import { Text } from "components/Text"
import { Price } from "components/Price"

export const SharingPotField = (props) => {
  const translator = useTranslator()
  const amount = props.value ? Number(props.value.amount) : null
  const { maxAllowed, maxValidated } = props.schema.options

  return (
    <div className={"flex items-center space-x-4"}>
      <div className={"grow"}>
        <PriceField {...props} />
      </div>
      {amount !== null && amount > maxAllowed.amount ? (
        <div className="shrink-0 text-secondary-dark flex items-baseline space-x-2">
          <ExclamationTriangle className="w-6 text-secondary-dark" />
          {maxValidated ? (
            <Text variant="caption">
              {translator.trans(
                "Form.SharingPotWidget.lastValidatedAmount",
                null,
                "components"
              )}{" "}
              <Price
                amount={maxValidated.amount}
                currency={maxValidated.currency.enum_value}
              />
            </Text>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
