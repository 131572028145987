import {
  createEditor,
  Element as SlateElement,
  Editor,
  Transforms,
  Range,
} from "slate"
import {
  Editable,
  Slate,
  useFocused,
  useSelected,
  useSlate,
  useSlateStatic,
  withReact,
} from "slate-react"
import { withHistory } from "slate-history"
import { Fragment, useCallback, useMemo } from "react"
import {
  Bold,
  Image,
  Italic,
  Link,
  LinkSlash,
  List,
  OrdonedList,
} from "components/Icon"
import classNames from "classnames"
import isHotkey from "is-hotkey"
import { useTranslator } from "components/Translator"
import slate, { serialize } from "remark-slate"
import { unified } from "unified"
import markdown from "remark-parse"
import flow from "lodash/flow"

export const WysiwygEditor = (props) => {
  const { enabledWidgets = [] } = props

  const editor = useMemo(() => {
    const enhanceEditor = flow(
      [
        withReact,
        withHistory,
        enabledWidgets.includes("link") ? withInlines : null,
        enabledWidgets.includes("image") ? withImages : null,
      ].filter((f) => f !== null)
    )

    return enhanceEditor(createEditor())
  }, [enabledWidgets])

  const renderElement = useCallback((props) => {
    return <Element {...props} />
  }, [])

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />
  }, [])

  const handleKeyDown = useCallback(
    (event) => {
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          event.preventDefault()

          const mark = HOTKEYS[hotkey]
          toggleMark(editor, mark)
        }
      }
    },
    [editor]
  )

  return (
    <Slate
      editor={editor}
      initialValue={
        props.initialValue ? markdownToSlate(props.initialValue) : initialValue
      }
      onChange={props.onChange}
    >
      <div
        className={classNames(
          "relative",
          "py-3",
          "px-4",
          "border",
          "box-border",
          "rounded",
          "border-grey-medium",
          "focus-within:border-primary-default",
          "focus-within:ring-4",
          "focus-within:ring-primary-lighter",
          "bg-white",
          "flex",
          "flex-col",
          "gap-4"
        )}
      >
        <Toolbar enabledWidgets={enabledWidgets} />
        <Editable
          placeholder={props.placeholder}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={handleKeyDown}
        />
      </div>
    </Slate>
  )
}

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
]

const Element = (props) => {
  switch (props.element.type) {
    case "ul_list":
      return (
        <ul
          {...props.attributes}
          className={classNames(
            props.attributes.className,
            "list-disc list-inside"
          )}
        >
          {props.children}
        </ul>
      )

    case "ol_list":
      return (
        <ol
          {...props.attributes}
          className={classNames(
            props.attributes.className,
            "list-decimal list-inside"
          )}
        >
          {props.children}
        </ol>
      )

    case "list_item":
      return <li {...props.attributes}>{props.children}</li>

    case "link":
      return (
        <a
          {...props.attributes}
          href={props.element.link}
          className={classNames(
            props.attributes.className,
            "text-primary-dark",
            "underline",
            "hover:text-primary-darker",
            "focus:text-primary-darker"
          )}
        >
          {props.children}
        </a>
      )

    case "image":
      return <ImageElement {...props} />

    default:
      return <p {...props.attributes}>{props.children}</p>
  }
}

const Leaf = (props) => {
  let Component = "span"

  if (props.leaf.italic) {
    Component = "em"
  }

  if (props.leaf.bold) {
    Component = "strong"
  }

  return (
    <Component
      {...props.attributes}
      className={classNames(props.attributes.className, {
        "font-bold": props.leaf.bold,
        italic: props.leaf.italic,
      })}
    >
      {props.children}
    </Component>
  )
}

const Toolbar = (props) => {
  const translator = useTranslator()

  return (
    <div className={"flex gap-2"}>
      {props.enabledWidgets.includes("bold") ? (
        <MarkButton
          format={"bold"}
          icon={<Bold className={"w-4 h-4"} />}
          label={
            translator.trans("WysiwygEditor.toolbar.bold", null, "components") +
            " (Ctrl-B)"
          }
        />
      ) : null}
      {props.enabledWidgets.includes("italic") ? (
        <MarkButton
          format={"italic"}
          icon={<Italic className={"w-4 h-4"} />}
          label={
            translator.trans(
              "WysiwygEditor.toolbar.italic",
              null,
              "components"
            ) + " (Ctrl-I)"
          }
        />
      ) : null}
      {props.enabledWidgets.includes("link") ? (
        <Fragment>
          <AddLinkButton />
          <RemoveLinkButton />
        </Fragment>
      ) : null}
      {props.enabledWidgets.includes("ul_list") ? (
        <BlockButton
          format={"ul_list"}
          icon={<List className={"w-4 h-4"} />}
          label={translator.trans(
            "WysiwygEditor.toolbar.ul",
            null,
            "components"
          )}
        />
      ) : null}
      {props.enabledWidgets.includes("ol_list") ? (
        <BlockButton
          format={"ol_list"}
          icon={<OrdonedList className={"w-4 h-4"} />}
          label={translator.trans(
            "WysiwygEditor.toolbar.ol",
            null,
            "components"
          )}
        />
      ) : null}
      {props.enabledWidgets.includes("image") ? <InsertImageButton /> : null}
    </div>
  )
}

const ToolbarButton = (props) => {
  const { label, isActive, icon, ...rest } = props

  return (
    <button
      type={"button"}
      title={label}
      className={classNames(
        "p-1",
        "enabled:hover:bg-primary-lighter",
        "focus-visible:outline-none",
        "focus-visible:bg-primary-lighter",
        "focus-visible:ring-2",
        "focus-visible:ring-primary-light",
        "rounded",
        {
          "opacity-50": !isActive,
        }
      )}
      {...rest}
    >
      {icon}
    </button>
  )
}

const MarkButton = (props) => {
  const editor = useSlate()

  const handleMouseDown = (event) => {
    event.preventDefault()
    toggleMark(editor, props.format)
  }

  return (
    <ToolbarButton
      label={props.label}
      icon={props.icon}
      isActive={isMarkActive(editor, props.format)}
      onMouseDown={handleMouseDown}
    />
  )
}

const BlockButton = (props) => {
  const editor = useSlate()

  const handleMouseDown = (event) => {
    event.preventDefault()
    toggleBlock(editor, props.format)
  }

  return (
    <ToolbarButton
      title={props.title}
      icon={props.icon}
      isActive={isBlockActive(editor, props.format)}
      onMouseDown={handleMouseDown}
    />
  )
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)

  return marks ? marks[format] === true : false
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  const { selection } = editor

  if (!selection) {
    return false
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })
  )

  return Boolean(match)
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  })

  const newProperties = {
    type: isActive ? "paragraph" : isList ? "list_item" : format,
  }

  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const LIST_TYPES = ["ul_list", "ol_list"]

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)

  const link = {
    type: "link",
    link: url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: "end" })
  }
}

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  })
}

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  })

  return Boolean(link)
}

const AddLinkButton = () => {
  const editor = useSlate()

  const handleMouseDown = (event) => {
    event.preventDefault()
    const url = window.prompt(
      translator.trans("WysiwygEditor.link.prompt", null, "components")
    )

    if (!url) {
      return
    }

    insertLink(editor, url)
  }

  const translator = useTranslator()
  const isActive = isLinkActive(editor)

  return (
    <ToolbarButton
      icon={<Link className={"w-4 h-4"} />}
      label={translator.trans("WysiwygEditor.toolbar.link", null, "components")}
      isActive={isActive}
      onMouseDown={handleMouseDown}
    />
  )
}

const RemoveLinkButton = () => {
  const editor = useSlate()

  const handleMouseDown = (event) => {
    event.preventDefault()

    unwrapLink(editor)
  }

  const translator = useTranslator()
  const isActive = isLinkActive(editor)

  return (
    <ToolbarButton
      icon={<LinkSlash className={"w-4 h-4"} />}
      label={translator.trans(
        "WysiwygEditor.toolbar.unlink",
        null,
        "components"
      )}
      isActive={isActive}
      disabled={!isActive}
      onMouseDown={handleMouseDown}
    />
  )
}
const withInlines = (editor) => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = (element) => element.type === "link" || isInline(element)

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data) => {
    const text = data.getData("text/plain")

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const withImages = (editor) => {
  const { insertData, isVoid } = editor

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element)
  }

  editor.insertData = (data) => {
    const text = data.getData("text/plain")
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split("/")

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result
            insertImage(editor, url)
          })

          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const insertImage = (editor, url) => {
  const text = { text: "" }
  const image = { type: "image", link: url, caption: "", children: [text] }
  Transforms.insertNodes(editor, image)
}

// TODO: would be better to try to download the image to get its mime type. There can be redirects or no extension in the URL
const isImageUrl = (url) => {
  if (!url) return false
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split(".").pop()
  return imageExtensions.includes(ext)
}

const isUrl = (url) => {
  try {
    new URL(url)
  } catch (err) {
    return false
  }

  return true
}

const imageExtensions = [
  "ase",
  "art",
  "bmp",
  "blp",
  "cd5",
  "cit",
  "cpt",
  "cr2",
  "cut",
  "dds",
  "dib",
  "djvu",
  "egt",
  "exif",
  "gif",
  "gpl",
  "grf",
  "icns",
  "ico",
  "iff",
  "jng",
  "jpeg",
  "jpg",
  "jfif",
  "jp2",
  "jps",
  "lbm",
  "max",
  "miff",
  "mng",
  "msp",
  "nef",
  "nitf",
  "ota",
  "pbm",
  "pc1",
  "pc2",
  "pc3",
  "pcf",
  "pcx",
  "pdn",
  "pgm",
  "PI1",
  "PI2",
  "PI3",
  "pict",
  "pct",
  "pnm",
  "pns",
  "ppm",
  "psb",
  "psd",
  "pdd",
  "psp",
  "px",
  "pxm",
  "pxr",
  "qfx",
  "raw",
  "rle",
  "sct",
  "sgi",
  "rgb",
  "int",
  "bw",
  "tga",
  "tiff",
  "tif",
  "vtf",
  "xbm",
  "xcf",
  "xpm",
  "3dv",
  "amf",
  "ai",
  "awg",
  "cgm",
  "cdr",
  "cmx",
  "dxf",
  "e2d",
  "egt",
  "eps",
  "fs",
  "gbr",
  "odg",
  "svg",
  "stl",
  "vrml",
  "x3d",
  "sxd",
  "v2d",
  "vnd",
  "wmf",
  "emf",
  "art",
  "xar",
  "png",
  "webp",
  "jxr",
  "hdp",
  "wdp",
  "cur",
  "ecw",
  "iff",
  "lbm",
  "liff",
  "nrrd",
  "pam",
  "pcx",
  "pgf",
  "sgi",
  "rgb",
  "rgba",
  "bw",
  "int",
  "inta",
  "sid",
  "ras",
  "sun",
  "tga",
  "heic",
  "heif",
]

const InsertImageButton = () => {
  const editor = useSlateStatic()

  const handleMouseDown = (event) => {
    event.preventDefault()

    const url = window.prompt(
      translator.trans("WysiwygEditor.image.prompt", null, "components")
    )

    if (!url) {
      return
    }

    if (!isImageUrl(url)) {
      alert(translator.trans("WysiwygEditor.image.error", null, "components"))
      return
    }

    insertImage(editor, url)
  }

  const translator = useTranslator()

  return (
    <ToolbarButton
      icon={<Image className={"w-4 h-4"} />}
      label={translator.trans(
        "WysiwygEditor.toolbar.image",
        null,
        "components"
      )}
      onMouseDown={handleMouseDown}
    />
  )
}

const ImageElement = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false} className={"relative"}>
        <img
          src={element.link}
          alt={element.caption}
          loading={"lazy"}
          className={classNames("block max-w-full max-h-80", {
            "ring-2 ring-primary-light": selected && focused,
          })}
        />
      </div>
    </div>
  )
}

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
}

export const valueToMarkdown = (value) => {
  return value
    .map((v) => serialize(v))
    .join("")
    .trim()
}

export const markdownToSlate = (input) => {
  return unified().use(markdown).use(slate).processSync(input).result
}
