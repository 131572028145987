import { Text } from "components/Text"
import PropTypes from "prop-types"

export const Error = ({ children, tag }) => {
  return (
    <Text variant="body2" className="text-error-default" tag={tag}>
      {children}
    </Text>
  )
}

Error.propTypes = {
  children: PropTypes.node,
}

export const ErrorsList = ({ errors }) => {
  return (
    <ul className="space-y-1">
      {Array.isArray(errors) ? (
        errors.map((error) => (
          <Error key={error} tag="li">
            {error}
          </Error>
        ))
      ) : (
        <Error key={errors} tag="li">
          {errors}
        </Error>
      )}
    </ul>
  )
}
