import { Label } from "components/Form/Label"
import { HelpMessage } from "components/Form/HelpMessage"
import { ErrorsList } from "components/ErrorsList"

export const FormRow = ({ label, id, description, errors, children }) => {
  return (
    <div className="space-y-1">
      {label ? (
        <div className="pl-4">
          <Label htmlFor={id}>{label}</Label>
        </div>
      ) : null}
      {description ? <HelpMessage>{description}</HelpMessage> : null}
      <div>{children}</div>
      {errors ? <ErrorsList errors={errors} /> : null}
    </div>
  )
}
