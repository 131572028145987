import { RawLink } from "components/Link"

export const CardActionArea = ({ children, href, onClick }) => {
  const Tag = href ? RawLink : "button"

  return (
    <Tag
      href={href}
      onClick={onClick}
      className="w-full text-left overflow-hidden"
    >
      {children}
    </Tag>
  )
}
