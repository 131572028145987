import { useReducer, useEffect, useRef, useCallback } from "react"
import { useSwipeable } from "react-swipeable"

/**
 * @param {number} length
 * @param {number} current
 */
function previous(length, current) {
  return (current - 1 + length) % length
}

/**
 * @param {number} length
 * @param {number} current
 */
function next(length, current) {
  return (current + 1) % length
}

/**
 * @param {HTMLElement} target
 */
function threshold(target) {
  const width = target.clientWidth
  return width / 3
}

const transitionTime = 400
const elastic = `transform ${transitionTime}ms cubic-bezier(0.68, -0.55, 0.265, 1.55)`
const smooth = `transform ${transitionTime}ms ease`

/**
 * @typedef {Object} State
 * @property {number} offset
 * @property {number} desired
 * @property {number} active
 */

/**
 * @type State
 */
const initialCarouselState = {
  offset: 0,
  desired: 0,
  active: 0,
}

/**
 * @typedef {Object} JumpAction
 * @property {"jump"} type
 * @property {number} desired
 */

/**
 * @typedef {Object} NextAction
 * @property {"next"} type
 * @property {number} length
 */

/**
 * @typedef {Object} PrevAction
 * @property {"prev"} type
 * @property {number} length
 */

/**
 * @typedef {Object} DoneAction
 * @property {"done"} type
 */

/**
 * @typedef {Object} DragAction
 * @property {"drag"} type
 * @property {number} offset
 */

/**
 * @typedef {JumpAction | NextAction | PrevAction | DoneAction | DragAction} Action
 */

/**
 * @param {State} state
 * @param {Action} action
 */
function carouselReducer(state, action) {
  switch (action.type) {
    case "jump":
      return {
        ...state,
        desired: action.desired,
      }

    case "next":
      return {
        ...state,
        desired: next(action.length, state.active),
      }

    case "prev":
      return {
        ...state,
        desired: previous(action.length, state.active),
      }

    case "done":
      return {
        ...state,
        offset: NaN,
        active: state.desired,
      }

    case "drag":
      return {
        ...state,
        offset: action.offset,
      }

    default:
      return state
  }
}

/**
 * @param {DragEvent} e
 * @param {import("react").Dispatch<Action>} dispatch
 * @param {number} length
 * @param {number} dir
 */
function swiped(e, dispatch, length, dir) {
  const t = threshold(e.event.target)
  const d = dir * e.deltaX

  if (d >= t) {
    dispatch({
      type: dir > 0 ? "next" : "prev",
      length,
    })
  } else {
    dispatch({
      type: "drag",
      offset: 0,
    })
  }
}

/**
 * @param {UseCarouselProps} props
 */
export function useCarousel({ length, interval, autoStart = true, onChange }) {
  const [state, dispatch] = useReducer(carouselReducer, initialCarouselState)
  const handlers = useSwipeable({
    onSwiping(e) {
      dispatch({
        type: "drag",
        offset: -e.deltaX,
      })
    },

    onSwipedLeft(e) {
      swiped(e, dispatch, length, -1)
    },

    onSwipedRight(e) {
      swiped(e, dispatch, length, 1)
    },

    trackMouse: false,
    trackTouch: true,
  })

  function previous() {
    stop()
    dispatch({ type: "prev", length })
  }

  function next() {
    stop()
    dispatch({ type: "next", length })
  }

  /**
   * @param {number} n
   */
  function setActive(n) {
    stop()
    dispatch({ type: "jump", desired: n })
  }

  /** @type import("react").MutableRefObject<number> */
  const intervalRef = useRef(null)

  function stop() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
  }

  const start = useCallback(
    function start() {
      stop()

      intervalRef.current = setInterval(
        () => dispatch({ type: "next", length }),
        interval
      )
    },
    [interval, length]
  )

  useEffect(() => {
    if (autoStart && interval) {
      start()
    }

    return () => {
      stop()
    }
  }, [autoStart, interval, start])

  useEffect(() => {
    if (onChange) {
      onChange()
    }
  }, [state.active, onChange])

  useEffect(() => {
    const id = setTimeout(() => dispatch({ type: "done" }), transitionTime)
    return () => clearTimeout(id)
  }, [state.desired])

  const style = {
    transform: `translateX(0)`,
    width: `${100 * (length + 2)}%`,
    left: `-${(state.active + 1) * 100}%`,
  }

  if (state.desired !== state.active) {
    const dist = Math.abs(state.active - state.desired)
    const pref = Math.sign(state.offset || 0)
    const dir =
      (dist > length / 2 ? 1 : -1) * Math.sign(state.desired - state.active)
    const shift = (100 * (pref || dir)) / (length + 2)
    style.transition = smooth
    style.transform = `translateX(${shift}%)`
  } else if (!isNaN(state.offset)) {
    if (state.offset !== 0) {
      style.transform = `translateX(${state.offset}px)`
    } else {
      style.transition = elastic
    }
  }

  return {
    active: state.active,
    setActive,
    previous,
    next,
    handlers,
    style,
    start,
    stop,
  }
}

/**
 * @typedef {Object} UseCarouselProps
 * @property {number} length
 * @property {number} [interval]
 * @property {boolean} [autoStart=true]
 * @property {() => void} onChange
 */
