import PropTypes from "prop-types"
import cx from "classnames"
import * as React from "react"

const CardContext = React.createContext()

export function Card(props) {
  const { children, href, spacing, className } = props
  const classNames = cx(
    "bg-white",
    "shadow-1",
    "rounded-lg",
    "overflow-hidden",
    className
  )

  return (
    <CardContext.Provider value={{ spacing }}>
      <div href={href} className={classNames}>
        {children}
      </div>
    </CardContext.Provider>
  )
}

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  spacing: PropTypes.oneOf(["small", "medium"]),
  elevation: PropTypes.oneOf([0, 1]),
}

Card.defaultProps = {
  spacing: "medium",
  elevation: 1,
}

export const useCardContext = () => {
  const context = React.useContext(CardContext)

  if (!context) {
    throw new Error(`useCardContext should be used in a Card`)
  }

  return context
}
