import BaseCropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import { useRef } from "react"
import { Button } from "components/Button"
import { RotateLeft, RotateRight } from "components/Icon"

export const Cropper = ({ src, style, onInitialized: onInitializedProps }) => {
  const cropperRef = useRef(null)

  const onInitialized = (cropper) => {
    cropperRef.current = cropper

    onInitializedProps?.(cropper)
  }

  const ROTATE_ANGLE = 90

  const rotateLeft = () => {
    cropperRef.current?.rotate(-ROTATE_ANGLE)
    cropperRef.current?.clear()
    cropperRef.current?.crop()
  }

  const rotateRight = () => {
    cropperRef.current?.rotate(ROTATE_ANGLE)
    cropperRef.current?.clear()
    cropperRef.current?.crop()
  }

  return (
    <div className="space-y-2 lg:space-y-4">
      <BaseCropper
        src={src}
        viewMode={2}
        onInitialized={onInitialized}
        scalable={false}
        zoomable={true}
        checkCrossOrigin={false}
        crossOrigin="anonymous"
        checkOrientation={false}
        style={style}
      />
      <div className="flex justify-center space-x-2">
        <Button
          type="button"
          color="secondary"
          onClick={rotateLeft}
          iconLeft={<RotateLeft className="w-6" />}
        />
        <Button
          type="button"
          color="secondary"
          onClick={rotateRight}
          iconLeft={<RotateRight className="w-6" />}
        />
      </div>
    </div>
  )
}
