import { useConnectedUser } from "components/ConnectedUserProvider"
import { ChoiceField } from "components/Form/ChoiceField"

export const CurrencyField = (props) => {
  const connectedUser = useConnectedUser()

  let value = props.value

  if (value === undefined) {
    value = connectedUser?.currency || DEFAULT_CURRENCY
  }

  return <ChoiceField {...props} value={value} />
}

const DEFAULT_CURRENCY = "EUR"
