import PropTypes from "prop-types"
import { Markdown } from "components/Markdown"
import { Text } from "components/Text"

export const HelpMessage = ({ children }) => {
  return (
    <Markdown
      linkTarget="_blank"
      components={{
        p({ children }) {
          return (
            <Text tag="p" variant="caption">
              {children}
            </Text>
          )
        },
      }}
    >
      {children}
    </Markdown>
  )
}

HelpMessage.propTypes = {
  children: PropTypes.node,
}
